
.iletisim-first-part{
    padding-top: 10rem;
    margin-top: 10rem;
}

.iletisim{
    max-width: 100vw;
    max-height: 100vh;
    text-align: center;
    overflow: auto;
    padding-bottom: 2rem;
}