
.hidden-header22{
    display:none;
}
.header22{
    width: 100vw;
    background-color: #141414;
    position: fixed;
    top:3.5rem;
    height: 7rem;
    z-index: 19;

.rightside-nav{
    padding-right: 2rem;
}
.navbar-style{
    
    background-color: #141414;
    
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: 0rem;
    margin-right: 0rem;
    z-index: 7;
   
    
   
    .container-style{
        padding-left:2rem;
        padding-right:1rem;
    }
   
    .style-brand-container{
        display:flex;
        width: 30rem;
    }
    .brand-style{
        color:#30d5c8;
        font-size: 2rem;
        font-weight: bold;
        font-family: Tahoma;
        padding-left: 1.5rem;
        padding-top: 0.5rem;
    }
    .collapse-nav{
        background-color: #141414;
    }
    .style-nav-item{
        font-size: 1.6rem;
        font-family: Tahoma;
        color:#30d5c8;
    }
    .style-nav-item:active{
        color:#30d5c8;
    }
    .style-nav-item:visited{
        color:#30d5c8;
    }
    .toggle-style{
        color:#30d5c8;
        font-size: 2.5rem;
        background-color: #141414;
    }
}
}
@media (max-width: 1000px) {
    
.header22{
    
.rightside-nav{
    padding-right: 1rem;
}
.navbar-style{
    padding-left: 1rem;
    padding-right: 1rem;
   
    
   
    .container-style{
        padding-left:1rem;
    }
   
    .style-brand-container{
        display:flex;
        width: 30rem;
    }
    .brand-style{
        padding-left: 1rem;
    }
}
}
}
