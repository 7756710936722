.entegrasyonlar-main-cards-container{
padding: 2rem;
.optimizeDepo-card-text{
    font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
}
    .container{
        padding: 2rem;
        background-color: transparent;
    }
.entegrasyonlar-card{
    border:none;
    background-color: transparent;
.cozumler-card-container{
    background-color: transparent;

    .cozumler-card-body{
        font-family: Tahoma;
        padding-left: 10rem;
        padding-right: 10rem;

        .cozumler-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            color:#281a39;
        }
        .cozumler-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
        }
    }
}}

.optimizeDepo{
    padding-bottom: 0rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .optimizeDepo-img-container{
        align-items: center;
        justify-content: center;
        border:none;
        border-radius: 0;
        padding: 2rem;
        background-color: transparent;
    }

    .optimizeDepo-text-container{
        font-family: Tahoma;
        border:none;
        border-radius: 0;
        background-color: transparent;

        .optimizeDepo-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
        }
        .optimizeDepo-card-text{
            font-size: 1.5rem;
            font-weight: 200;
            line-height: 2rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
            
            .link-hakkimizda{
                text-decoration: none;
                color:#30d5c8;
            }
        }
    }
}
}
@media (max-width: 1000px){
    .entegrasyonlar-main-cards-container{
        .optimizeDepo-card-text{
                    line-height: normal;
                    padding-bottom: 2rem;
                    padding-left: 0rem;
                    padding-right: 0rem;
        }
        .entegrasyonlar-card{

        .cozumler-card-container{
        
            .cozumler-card-body{
                padding-left: 2rem;
                padding-right: 2rem;
        
                .cozumler-card-title{
                    font-size: 2rem;
                }
            }
        }}
        
        .optimizeDepo{
        
           
        
            .optimizeDepo-text-container{
        
                .optimizeDepo-card-title{
                    font-size: 2rem;
                }
                .optimizeDepo-card-text{
                    line-height: normal;
                    
            }
        }
        }
}}