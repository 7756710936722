.farkımız-card {
    background-color: transparent;
    border:none;
      
    .farkımız-card-container{
      min-height: 20rem;
      padding-top: 4rem;
      padding-bottom: 4rem;

       .farkımız-card-body{
        font-family: Tahoma;
        padding-left: 10rem;
        padding-right: 10rem;

           .farkımız-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
            }
            .farkımız-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
            }
        }
    }
}
.farkımız-cards-container{
    padding: 0.5rem;
    background-color: transparent;
    .position-up{
        padding: 0rem;
    }

    .farkımız-one-card-container{
    justify-items: center;
    align-items: center;
    background-color: #141414;
    padding: 2rem;
    border:none;
    margin: 0.5rem;

    .farkımız-card-header{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        border:1px solid #30D5C8;
        justify-items: center;
        align-items: center;
        font-size: 3.5rem;
        color:#30D5C8;
        transform-style: preserve-3d;
        animation:  fadeInLeft 3s forwards;
        animation-delay: 8s;
    }
}
    .farkımız-card-title{
        font-size: 3rem;
        font-weight: 600;
        text-align: center;
        padding-bottom: 2rem;
        color:#30D5C8;
        }
        .farkımız-card-text{
        font-size: 1.5rem;
        font-weight: 200;
        line-height: 2rem;
        padding-bottom: 2rem;
        color:white;
        }
}
@media (max-width: 1000px) {
    .farkımız-card {
          
        .farkımız-card-container{

           .farkımız-card-body{
            padding-left: 2rem;
            padding-right: 2rem;
    
               .farkımız-card-title{
                font-size: 2rem;
                }
            }
        }
    }
    .farkımız-cards-container{
        .position-up{
            display:block;
        }
    }
}
