
.hizmetlerimiz-main-cards-container{
    padding-top: 0rem;
    margin-top: 1rem;
    .bold{
        font-weight: 800;
    }

    .container{
        padding: 2rem;
    }
  .entegrasyonlar-card{
    background-color: transparent;
    font-family: Tahoma;
    font-size: 1.5rem;

    .cozumler-card-container{
        background-color: transparent;
    }
}
.optimizeDepo-text-container{
    background-color: transparent;
}
.modul-bolumu-main-container{
    background-color: transparent;
.justify-content-md-end{

    justify-content: end;

    .button-style-cozumler-card{
        background-color:#ec1c24;
        color:white;
        border:none;
        
    }
}
}
}
@media (max-width: 1000px) {
    .hizmetlerimiz-main-cards-container{
        .entegrasyonlar-card{
            .cozumler-card-container{
                .cozumler-card-body{
                padding: 2rem;
                }
            }
        }
    }
}