.table-general{
    margin: 3rem;
    overflow: auto;
.header-of-the-table{
    background-color: #141414;
    color:#30d5c8;
    text-align: center;
    font-size: 1.2rem;
}
.correct-icon{
    color:green;
    font-size: 2rem;
    text-align: center;
}
.wrong-icon{
    color:red;
    font-size: 2rem;
    text-align: center;
}
.title-of-the-property{
    background-color: #eeeeee;
    color:#141414;
    font-family: Tahoma;
    font-weight: bold;
    font-size: larger;
    text-align: center;
}
}
