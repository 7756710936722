.entegrasyonlar-main-cards-container{
    padding-top: 7rem;
.container{
    padding: 2rem;
}
.entegrasyonlar-card{
    border:none;
    background-color: transparent;
    
.cozumler-card-container{
    padding:2rem;
    background-color: transparent;

    .cozumler-card-body{
        font-family: Tahoma;
        padding-left: 5rem;
        padding-right: 5rem;

        .cozumler-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
        }
        .cozumler-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
        }
        .button-style-cozumler-card{
            background-color:#ec1c24;
            color:white;
            border:none;
        }
    }

}}

.entegreDepo-text-container{
    background-color: transparent;
}
}

@media (max-width: 1000px){
    .entegrasyonlar-main-cards-container{
    
    .entegrasyonlar-card{
        
    .cozumler-card-container{
        padding:0rem;
    
        .cozumler-card-body{
            padding-left: 0rem;
            padding-right: 0rem;
    
            .cozumler-card-title{
                font-size: 2rem;
            }
            .cozumler-card-text{
                line-height: normal;
            }
            .button-style-cozumler-card{
                background-color:#ec1c24;
                color:white;
                border:none;
            }
        }
    
    }}
    }
    
}