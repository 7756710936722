.entegrasyonlar-card{
    border:none;
    margin-top: 7rem;
    background-color: transparent;
.cozumler-card-container{
    min-height: 25rem;
    padding-top: 7rem;
    padding-bottom: 1rem;

    .cozumler-card-body{
        font-family: Tahoma;
        padding-left: 10rem;
        padding-right: 10rem;

        .cozumler-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
        }
        .cozumler-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
        }
    }
}}

.optimizeDepo{
    padding-top: 5rem;
    padding-bottom: 0rem;
    align-items: center;
    justify-content: center;

    .optimizeDepo-img-container{
        align-items: center;
        justify-content: center;
        border:none;
        border-radius: 0;
        padding: 2rem;
    }

    .optimizeDepo-text-container{
        font-family: Tahoma;
        border:none;
        border-radius: 0;

        .optimizeDepo-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
        }
        .optimizeDepo-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}
@media (max-width: 1000px){
    .entegrasyonlar-card{
        .cozumler-card-container{
            .cozumler-card-body{
                padding: 2.5rem;
                .cozumler-card-title{
                    font-size: 2rem;
                }
                .cozumler-card-text{
                    font-size: 1.5rem;
                    text-align: justify;
                }
            }
        }
    }
}