.aydinlatma-main{
    padding-top:15rem;
    padding-left: 5rem;
    padding-right: 5rem;
    text-align: justify;
    .aydinlatma-baslik{
        font-family: Tahoma;
        font-size: 2rem;
        font-weight: 800;
        color:black;

    }
    .aydinlatma-text{
        font-family: Tahoma;
        font-size: 1.5rem;
        color:black;
        
    }
}