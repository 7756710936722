$blue-color:#222cfd;
$orange-color:#eb1a00;
$pink-color:#ea0f2d;
$green-color:#006400;
$lightgray-color:	#36454F;
$darkgray-color:#3b3b3b;


@import "animate.css";

@font-face {
  font-family: 'Ysabeau_Infant_Italic';
  src: url(./assets/fonts/Ysabeau_Infant/static/YsabeauInfant-LightItalic.ttf) format('ttf');
}
@font-face {
  font-family: 'Ysabeau_Infant';
  src: url(./assets/fonts/Ysabeau_Infant/static/YsabeauInfant-Light.ttf) format('ttf');
}
@font-face {
  font-family: 'Lexend_Peta';
  src: url(./assets/fonts/Lexend_Peta/LexendPeta-VariableFont_wght.ttf) format('ttf');
}
@font-face {
  font-family: 'Ultra';
  src: url(./assets/fonts/Ultra/Ultra-Regular.ttf) format('ttf');
}
input,textarea{
  font-family: "Ysabeau_Infant";
}
