.carusel-main-block{
  background-color: transparent;
  padding-top: 13rem;
  border:none;

.koyu{
    opacity: 0.95;
} 
.text-zonem{
  font-family: Tahoma;
  top:28rem;
  background: #141414;
  opacity: 0.75;
  z-index: 3;
  height: min-content;

  .header-style{
    font-size: 3rem;
    font-weight:900;
    letter-spacing: 1rem;
    color: #30D5C8;
    z-index: 5!important;
    opacity: 1!important;
  }
  .p-style{
    padding-top: 3rem;
    font-size: 2rem;
    font-weight:400;
    letter-spacing: 0.5rem;
    color: #30D5C8;
    z-index: 5!important;
    opacity: 1!important;
  }
}
}
@media (max-width: 1000px) {
  .carusel-main-block{
  
  .text-zonem{
    top:18rem;
  
    .header-style{
      font-size: 2rem;
    }
    .p-style{
      padding-top: 1.5rem;
      font-size: 1.5rem;
    }
  }
  }
}
