.cozumler-card{
    background-color: transparent;
    border:none;
.cozumler-card-container{
    min-height: 25rem;
    padding-top: 7rem;
    padding-bottom: 3rem;

    .cozumler-card-body{
        font-family: Tahoma;
        padding-left: 10rem;
        padding-right: 10rem;

        .cozumler-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
        }
        .cozumler-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
        }
        .button-style-cozumler-card{
            background-color:#ec1c24;
            color:white;
            border:none;
        }
    }
}
}
.cozumler-cards-ozet-container{
    min-height: 150vh;
    background-color:transparent;
    padding: 5rem;
    .cards-part-one{
        padding: 2rem;

        .cozumler-card-border{
            background-color:  #141414;
        }


        .cozumler-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#30d5c8;
        }
        .cozumler-card-text{
            font-size: 1.5rem;
            font-weight: 200;
            line-height: 2rem;
            padding-bottom: 2rem;
            color:#f6f6f6;
            padding: 2rem;
        }
        .button-style-cozumler-card{
            background-color:#ec1c24;
            color:white;
            border:none;
        }
    }
    .cards-part-two{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;

        .cozumler-card-border{
            background-color:  #141414;
        }
        .cozumler-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#30d5c8;
        }
        .cozumler-card-text{
            font-size: 1.5rem;
            font-weight: 200;
            line-height: 2rem;
            padding-bottom: 2rem;
            color:#f6f6f6;
            padding: 2rem;
        }
        .button-style-cozumler-card{
            background-color:#ec1c24;
            color:white;
            border:none;
        }
    }
}
@media (max-width: 1000px) {
    .cozumler-card{
    .cozumler-card-container{
    
        .cozumler-card-body{
            padding-left: 3rem;
            padding-right: 3rem;
    
            .cozumler-card-title{
                font-size: 2rem;
            }
            .cozumler-card-text{
                font-size: 1.5rem;
            }
        }
    }
    }
    .cozumler-cards-ozet-container{
        padding: 3rem;
        .cards-part-one{
            padding: 1rem;
            display: block;
    
    
    
            .cozumler-card-title{
                font-size: 2rem;
            }
        }
        .cards-part-two{
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            display:block;
    
            .cozumler-card-title{
                font-size: 2rem;
            }
        }
    }
}