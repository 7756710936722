html{
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 "Ysabeau_Infant", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#222cfd;
  background: white;
  overflow-x: hidden;
  display: block;
}
