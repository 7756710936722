.footer-main-container{
    background-color: #141414;
    padding: 3rem;
   .footer-one-card-genel{
    background-color: #141414;
    align-items: center;
    justify-content: center;
    border:none;
    margin: 1rem;
    text-decoration: none;

    .style-message-image-footer{
        color:#30d5c8;
        font-size: 5rem;
        align-items: center;
        justify-content: center;
    }
    .style-footer-messageSend{
        color:#30d5c8;
        font-size: 2rem;
        font-weight: bold;
        font-family: Tahoma;
        padding-left: 1.5rem;
        padding-top: 0.5rem;
        text-align: center;
    }
    .footer-right-side-text{
        font-family: Tahoma;
        font-size: 1.6rem;
        color:#30d5c8;
        padding-top: 6rem;
        text-align: center;
    }

    .style-brand-container{
        display:flex;
    
    .brand-style{
        color:#30d5c8;
        font-size: 2rem;
        font-weight: bold;
        font-family: Tahoma;
        padding-left: 1.5rem;
        padding-top: 0.5rem;
    }
}
    .footer-left-side-text{
        font-family: Tahoma;
        font-size: 1.2rem;
        color:#30d5c8;
        padding-top: 10rem;
    }
    .footer-text-muted{
        color:grey;
        font-size: 1rem;
    }
    .footer-link{
        text-decoration: none;
    }
    .footer-link-text{
        background-color: #141414;
        color: #30d5c8;
        font-family: Tahoma;
        font-size: 1.4rem;
        border:none;
    }
    .footer-form-labels-style{
        color: #30d5c8;
        font-family: Tahoma;
        font-size: 1.5rem;
        &:active {
            background-color: #141414;
            border-bottom: 2px solid red;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            color:#30d5c8;
        }
    }
    .button-style-footer-last-card{
        background-color:#ec1c24;
        color:white;
        border:none;
    }
   .footer-form-controls-style{
    background-color: #141414;
    border-bottom: 2px solid grey;
    border-top: 2px solid #141414;
    border-left: 2px solid #141414;
    border-right: 2px solid #141414;
    border-radius: 0;
    color:#30d5c8;
    font-size: 1.4rem;
    
    &:checked{
        background-color: #141414;
        border-bottom: 2px solid red;
        border-top: 2px solid #141414;
        border-left: 2px solid #141414;
        border-right: 2px solid #141414;
        border-radius: 0;
        color:#30d5c8;
    }
    &:valid{
        background-color: #141414 !important;
        border-bottom: 2px solid grey;
        border-top: 2px solid #141414;
        border-left: 2px solid #141414;
        border-right: 2px solid #141414;
        border-radius: 0;
        color:#30d5c8;
    }
    &::placeholder{
        color:#30d5c8;
        font-size: 1.4rem;
    }
    &:active {
        background-color: #141414;
        border-bottom: 2px solid red;
        border-top: 2px solid #141414;
        border-left: 2px solid #141414;
        border-right: 2px solid #141414;
        border-radius: 0;
        color:#30d5c8;
    }
    &:visited{
        background-color: #141414;
    border-bottom: 2px solid grey;
    border-top: 2px solid #141414;
    border-left: 2px solid #141414;
    border-right: 2px solid #141414;
    border-radius: 0;
    color:#30d5c8;
    }
    &:hover{
        background-color: #141414;
    border-bottom: 2px solid red;
    border-top: 2px solid #141414;
    border-left: 2px solid #141414;
    border-right: 2px solid #141414;
    border-radius: 0;
    color:#30d5c8;
    }
    [dir=rtl] &:active, [dir=rtl] &:checked,
    [dir=rtl] &:valid, [dir=rtl] &:default, 
    [dir=rtl] &:focus-within, [dir=rtl] &:visited {
    border:none;
    border-radius: 0;
    }
    
   }
 }
}
@media (max-width: 1000px){
    .footer-main-container{
        .pddng-set{
            padding: 1rem;
        }
    .footer-one-card-genel{
        text-align: left;
    }
   }
}
    
