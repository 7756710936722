.entegreDepo{
    padding-top: 3rem;
    padding-bottom: 5rem;
    align-items: center;
    justify-content: center;

    .entegreDepo-img-container{
        align-items: center;
        justify-content: center;
        border:none;
        border-radius: 0;
        padding: 2rem;
        background-color: transparent;
    }

    .entegreDepo-text-container{
        font-family: Tahoma;
        border:none;
        border-radius: 0;
        background-color: transparent;

        .entegreDepo-card-title{
            font-size: 3rem;
            font-weight: 600;
            text-align: center;
            padding-bottom: 2rem;
            color:#281a39;
        }
        .entegreDepo-card-text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
        .button-style-entegreDepo-card{
            background-color:#ec1c24;
            color:white;
            border:none;
        }
    }
}
@media (max-width: 1000px) {
    .entegreDepo{
     .block-display{
        display: block;
    
        .entegreDepo-text-container{
    
            .entegreDepo-card-title{
                font-size: 2rem;
            }
            .entegreDepo-card-text{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
}
}