.hidden-header11{
    display:none;
}

.header1{
    background: #141414;
    font-size: 1.2rem;
    color:#30d5c8;
    padding-left: 3rem;
    padding-right: 3rem;
    z-index: 15;
    position:fixed;
    top:0;

    .p1{
        padding-top: 0.5rem;
    }
    .text-zone{
        font-family: Tahoma;
        color:#30d5c8;
    }
    .border-color{
        border-right: 1px solid #30d5c8;
    }
    .img-font-size{
        font-size: 1.6rem;
    }
}
@media (max-width: 1000px) {
    .header1{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}